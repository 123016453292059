<template>
	<div>
		<div style="margin:20px">
			<el-table :data="tableData" border style="">
				<el-table-column prop="name" label="商品名称" width="">
				</el-table-column>
				<el-table-column prop="integral" label="花费积分" width="">
				</el-table-column>
				<el-table-column prop="date" label="日期">
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import {
		scorelist
	} from '@/api/home/index'
	export default {
		data() {
			return {
				tableData:null,
				userInfo:null,
			}
		},

		created() {
			this.userInfo = JSON.parse(sessionStorage.getItem("getUserInfo"))
			// this.scorelist()
		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {
			//---------------我的消费积分记录接口-------------------------
			scorelist:function(){
				let data = {
					uid:this.userInfo.id
				}
				scorelist(data).then(res=>{
					this.tableData = res.data;
					// console.log(this.tableData)
				})
			}
		}
	}
</script>

<style lang="less" scoped>

</style>